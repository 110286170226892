<template>
  <div v-loading="loading">
    <el-row>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span class="title_fw">项目收入统计</span>
          <span class="title_right"
            ><SearchUserDate
              :title="titleYear"
              :yearIsShow="true"
              :introductionType="'projectId'"
              :width="'100'"
              :type="'Histogram'"
              :list="dictData.yearList"
              :twoValue="sectionInfo.projectIncomeId"
              v-model="sectionInfo.incomeYear"
              @change="onHistogramChange"
          /></span>
          <span class="title_right"
            ><SearchUserDate
              :title="'项目名称：'"
              :type="'Histogram'"
              :width="'400'"
              :introductionType="'projectId'"
              :clearable="true"
              :filterable="true"
              :list="projectList"
              :yearValue="sectionInfo.incomeYear"
              v-model="sectionInfo.projectIncomeId"
              @change="onHistogramChange"
          /></span>
          <el-button class="title_right" type="primary" size="small" @click="onHistogramImg"
            >数据来源说明</el-button
          >
        </div>
        <!-- <Histogram :incomeList="incomeList" /> -->
      </el-card>
    </el-row>
    <el-row class="statistics_top">
      <el-card class="box-card" style="width: 33%">
        <div slot="header" class="clearfix">
          <span class="title_fw">商务类型</span>
          <span class="title_right"
            ><SearchUserDate
              :type="'BusinessType'"
              :introductionType="'projectManager'"
              :yearIsShow="true"
              :title="titleYear"
              :list="dictData.yearList"
              :twoValue="sectionInfo.businessTypeManager"
              v-model="sectionInfo.businessTypeYear"
              @change="onBusinessTypeChange"
          /></span>
          <span class="title_right"
            ><SearchUserDate
              :title="'项目经理：'"
              :width="'90'"
              :type="'BusinessType'"
              :introductionType="'projectManager'"
              :clearable="true"
              :filterable="true"
              :list="projectManagerlist"
              :yearValue="sectionInfo.businessTypeYear"
              v-model="sectionInfo.businessTypeManager"
              @change="onBusinessTypeChange"
          /></span>
          <el-button class="title_right" type="primary" size="small" @click="onBusinessTypeImg"
            >数据来源说明</el-button
          >
        </div>
        <!-- <BusinessType :businessTypeList="businessTypeList" /> -->
      </el-card>
      <el-card class="box-card" style="width: 33%">
        <div slot="header" class="clearfix">
          <span class="title_fw">项目来源</span>
          <span class="title_right"
            ><SearchUserDate
              :type="'ProjectSource'"
              :introductionType="'projectManager'"
              :yearIsShow="true"
              :title="titleYear"
              :list="dictData.yearList"
              :twoValue="sectionInfo.projectSourceManager"
              v-model="sectionInfo.projectSourceYear"
              @change="onProjectSourceChange"
          /></span>
          <span class="title_right"
            ><SearchUserDate
              :title="'项目经理：'"
              :width="'90'"
              :type="'ProjectSource'"
              :introductionType="'projectManager'"
              :clearable="true"
              :filterable="true"
              :list="projectManagerlist"
              :yearValue="sectionInfo.projectSourceYear"
              v-model="sectionInfo.projectSourceManager"
              @change="onProjectSourceChange"
          /></span>
        </div>
        <!-- <ProjectSource :projectSourceList="projectSourceList" /> -->
      </el-card>
      <el-card class="box-card" style="width: 33%">
        <div slot="header" class="clearfix">
          <span class="title_fw">业务种类</span>
          <span class="title_right"
            ><SearchUserDate
              :type="'BusinessLines'"
              :introductionType="'projectManager'"
              :yearIsShow="true"
              :title="titleYear"
              :list="dictData.yearList"
              :twoValue="sectionInfo.businessLinesManager"
              v-model="sectionInfo.businessLinesYear"
              @change="onBusinessLinesChange"
          /></span>
          <span class="title_right"
            ><SearchUserDate
              :title="'项目经理：'"
              :width="'90'"
              :type="'BusinessLines'"
              :introductionType="'projectManager'"
              :clearable="true"
              :filterable="true"
              :list="projectManagerlist"
              :yearValue="sectionInfo.businessLinesYear"
              v-model="sectionInfo.businessLinesManager"
              @change="onBusinessLinesChange"
          /></span>
        </div>
        <!-- <BusinessLines :businessLinesList="businessLinesList" /> -->
      </el-card>
    </el-row>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img
          :src="`https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/${imgUrl}.png?${new Date().getTime()}`"
          alt="数据来源说明"
          width="100%"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    // Histogram: () => import('../histogram.vue'),
    // BusinessLines: () => import('../businessLines.vue'),
    // BusinessType: () => import('../businessType.vue'),
    // ProjectSource: () => import('../projectSource.vue'),
    // SearchUserDate: () => import('../searchUserDate.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      projectList: [], // 项目数组
      dictData: {
        yearList: [],
      },
      incomeList: [], // 项目收入统计
      businessTypeList: [], // 商务类型
      projectSourceList: [], // 项目来源
      businessLinesList: [], // 业务种类
      sectionInfo: {
        // 条件筛选
        // 年份
        incomeYear: null,
        businessLinesYear: null,
        businessTypeYear: null,
        projectSourceYear: null,
        projectMember: '', // 项目成员
        projectIncomeId: null, // 项目收入统计名称
        businessTypeManager: '', // 商务类型项目经理
        projectSourceManager: '', // 项目来源项目经理
        businessLinesManager: '', // 业务种类项目经理
      },
      projectManagerlist: [], //项目经理
      titleYear: '统计时间：',
      isDialog: false,
      imgUrl: '',
    }
  },
  provide() {
    return {}
  },
  computed: {
    monthGenerate() {
      const arr = []
      for (let index = 0; index < 12; index++) {
        arr.push({ id: index + 1, dictName: `${index + 1}月`, dictVal: `${index + 1}` })
      }
      return arr
    },
  },
  created() {
    this.getType()
  },
  watch: {},
  mounted() {},
  methods: {
    onBusinessTypeImg() {
      this.imgUrl = '商务、项目、业务等分析'
      this.isDialog = true
    },
    onHistogramImg() {
      this.imgUrl = '项目收入统计'
      this.isDialog = true
    },
    onHistogramChange(arr) {
      this.incomeList = arr
    },
    onBusinessLinesChange(arr) {
      this.businessLinesList = arr
    },
    onBusinessTypeChange(arr) {
      this.businessTypeList = arr
    },
    onProjectSourceChange(arr) {
      this.projectSourceList = arr
    },
    async getType() {
      const businessLines = await this.$api.dict.listSysDictData('BUSINESS_LINES', true)
      const businessType = await this.$api.dict.listSysDictData('BUSINESS_TYPE', true)
      const projectSource = await this.$api.dict.listSysDictData('PROJECT_SOURCE', true)
      this.loading = true
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(async res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.incomeYear = dictVal
          this.sectionInfo.businessLinesYear = dictVal
          this.sectionInfo.businessTypeYear = dictVal
          this.sectionInfo.projectSourceYear = dictVal
          const queryProjectIncomeStatistics =
            await this.$api.statistics.queryProjectIncomeStatistics(`date=${dictVal}`)
          const { projectIncomeStatisticsVo = {} } = queryProjectIncomeStatistics.data
          const nameMapping = {
            actualAmount: '项目实际金额',
            contractAmount: '合同金额',
            invoicedAmount: '已开票金额',
            receivedAmount: '已收款金额',
            unreceivedInvoicedAmount: '已开票未收款金额',
            unreceivedUnissuedAmount: '未开票未收款金额',
            unissuedAmount: '未开票金额',
            totalTax: '总税费',
          }
          this.incomeList = Object.keys(projectIncomeStatisticsVo)
            .filter(key => nameMapping.hasOwnProperty(key)) // 只处理有映射的键
            .map(key => ({
              name: nameMapping[key], // 根据键获取名称
              data: projectIncomeStatisticsVo[key],
            }))

          const projectBusinessCategorySummary =
            await this.$api.statistics.projectBusinessCategorySummary(`date=${dictVal}`)
          this.businessLinesList =
            projectBusinessCategorySummary.data.businessCategoryResponseList.map(item => {
              const businessLinesItem = businessLines.data.find(
                v => v.dictVal === item.categoryName
              )
              return {
                value: item.categoryAmount,
                percentage: Number((item.percentage * 100).toFixed(2)),
                categoryCount: item.categoryCount,
                name: businessLinesItem ? businessLinesItem.dictName : item.categoryName, // 如果找不到则使用原名称
              }
            })

          const projecBusinessTypeSummary = await this.$api.statistics.projecBusinessTypeSummary(
            `date=${dictVal}`
          )
          this.businessTypeList = projecBusinessTypeSummary.data?.businessTypeResponse.map(item => {
            const businessTypeItem = businessType.data.find(
              v => v.dictVal === item.businessTypeName
            )
            return {
              value: item.businessTypeAmount,
              businessPercentage: Number((item.businessPercentage * 100).toFixed(2)),
              businessTypeCount: item.businessTypeCount,
              name: businessTypeItem ? businessTypeItem.dictName : item.businessTypeName, // 如果找不到则使用原名称
            }
          })

          const queryProjectSourceSummary = await this.$api.statistics.queryProjectSourceSummary(
            `date=${dictVal}`
          )
          this.projectSourceList = queryProjectSourceSummary.data?.projectSourceResponse.map(
            item => {
              const projectSourceItem = projectSource.data.find(
                v => v.dictVal === item.projectSourceName
              )
              return {
                value: item.projectSourceAmount,
                projectPercentage: Number((item.projectPercentage * 100).toFixed(2)),
                projectSourceCount: item.projectSourceCount,
                name: projectSourceItem ? projectSourceItem.dictName : item.projectSourceName, // 如果找不到则使用原名称
              }
            }
          )
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })

      this.$api.project
        .getProjectMember()
        .then(res => {
          this.projectMemberlist = res.data.map(v => ({
            id: v.id,
            dictName: v.userName,
            dictVal: v.id,
          }))
          this.projectMemberlist.unshift({
            id: '1',
            dictName: '全部',
            dictVal: '',
          })
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.project
        .getProjectInfoByStaffId()
        .then(res => {
          this.projectList = res.data?.projectNameRespList.map(v => ({
            id: v.id,
            dictName: v.projectName,
            dictVal: v.id,
          }))
          this.projectManagerlist = res.data?.projectManagerNameRespList.map(v => ({
            id: v.id,
            dictName: v.userName,
            dictVal: v.id,
          }))
          this.projectManagerlist.unshift({
            id: '1',
            dictName: '全部',
            dictVal: '',
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
.statistics_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
}
</style>
